var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.isLoading)?_c('div',[_c('v-progress-circular',{style:(_vm.getLoaderStyle(70)),attrs:{"width":2,"size":50,"indeterminate":"","color":"primary"}})],1):_c('div',[(_vm.deployment)?_c('div',[_c('v-flex',{staticClass:"mb-2"},[_c('AutoDeploymentDetail')],1),(_vm.isDeploymentUnsuccessful || !_vm.deployment.contractors?.length)?_c('v-card',{staticClass:"pa-2 mb-2 text-center white--text",attrs:{"color":"red"}},[_c('h5',[_vm._v("Auto deployment hasn't been successful, advising manual deployment")])]):_vm._e(),(_vm.deployment.contractors?.length)?_c('v-flex',[_c('v-expansion-panel',{attrs:{"value":_vm.accordionIndexToOpen,"expand":""}},_vm._l((_vm.deployment.contractors),function(contractor,index){return _c('v-expansion-panel-content',{key:`deployment-${index}`,attrs:{"hide-actions":_vm.isJobStatusNotOffered(contractor.appointmentRequest)},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-flex',[_c('v-layout',{staticClass:"expansion-heading",attrs:{"justify-space-between":"","align-center":""}},[_c('v-layout',{attrs:{"column":""}},[_c('h5',{staticClass:"text-uppercase"},[_vm._v(_vm._s(contractor.contractorName))]),_c('h6',[_vm._v(_vm._s(index + 1)+". "+_vm._s(contractor.strategy))])]),_c('v-chip',{attrs:{"small":"","color":contractor.appointmentRequest
                        ? _vm.appointmentStatusMap[contractor.appointmentRequest.status].color
                        : _vm.nextToBeDeployed(_vm.deployment.contractors, index)
                        ? 'orange'
                        : 'grey',"text-color":"white"}},[_vm._v(" "+_vm._s(contractor.appointmentRequest ? _vm.appointmentStatusMap[contractor.appointmentRequest.status].text : _vm.showTimer(_vm.deployment.contractors, index) ? `Pending ${ _vm.timerCountdown.minutes ? _vm.timerCountdown.minutes + ' : ' + _vm.timerCountdown.seconds : '' }` : 'Not Offered'))])],1)],1)]},proxy:true}],null,true)},[(
                contractor.appointmentRequest &&
                contractor.appointmentRequest.status !== _vm.appointmentStatusEnum.NotOffered
              )?_c('AutoDeploymentContractorDetail',{attrs:{"contractor-appointed-id":contractor.contractorId,"deployment-id":_vm.deploymentId}}):_vm._e()],1)}),1)],1):_vm._e()],1):_c('div',[_vm._v("Deployment Currently Not Running")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }